import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PortfolioComponent } from "./portfolio/portfolio.component";
import { Error404Component } from "./error404/error404.component";
import { PublicationComponent } from "./publication/publication.component";
import { ProjectComponent } from "./portfolio/project/project.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";

const routes: Routes = [
  { path: "", component: PortfolioComponent },
  { path: "portfolio", component: PortfolioComponent },
  { path: "error", component: Error404Component },
  { path: "publication", component: PublicationComponent },
  { path: "project", component: ProjectComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: '**', redirectTo: "error" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
