import { Component, HostListener, OnInit } from '@angular/core';

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 0;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  constructor() { }
  @HostListener("window:scroll", ["$event"])
  hasScrolled() {
    var st = window.pageYOffset;
    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return;
    var navbar = document.getElementById("navbar-top");
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      if (navbar) {
        if (navbar.classList.contains("nav-down")) {
          navbar.classList.remove("nav-down");
          navbar.classList.add("nav-up");
        }
      }

      // $('.navbar.nav-down').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      //  $(window).height()
      if (st + window.innerHeight < document.body.scrollHeight) {
        // $('.navbar.nav-up').removeClass('nav-up').addClass('nav-down');
        if (navbar) {
          if (navbar.classList.contains("nav-up")) {
            navbar.classList.remove("nav-up");
            navbar.classList.add("nav-down");
          }
        }
      }
    }

    lastScrollTop = st;
  }

  ngOnInit(): void {

    var navbar = document.getElementById("navbar-top");
    navbar.classList.add("nav-down");
    this.hasScrolled();
    
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("sections-page");
    var navbar = document.getElementById("navbar-top");
    navbar.classList.remove("nav-down");
    navbar.classList.remove("nav-up");
  }

}
